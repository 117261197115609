<template>
  <div class="PacienteForm">
    <v-card
      class="mt24"
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-card-title v-text="'Perfil'" />
      <v-card-text>
        <v-row class="mb4">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Nombre*"
              v-model="paciente.nombre_pac"
              :error-messages="errors.nombre_pac"
              disabled
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Apellido paterno*"
              v-model="paciente.ape_pat_pac"
              :error-messages="errors.ape_pat_pac"
              disabled
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-text-field
              label="Apellido materno"
              v-model="paciente.ape_mat_pac"
              :error-messages="errors.ape_mat_pac"
              disabled
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              label="Género*"
              v-model="paciente.genero_pac"
              :error-messages="errors.genero_pac"
              :items="[
                { text: 'Femenino', value: 'femenino' },
                { text: 'Masculino', value: 'masculino' },
                { text: 'Otro', value: 'otro' },
                {
                  text: 'Prefiero no especificar',
                  value: 'prefiero no especificar',
                },
              ]"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <DatePicker
              label="Fecha de nacimiento*"
              v-model="paciente.fecha_nac_pac"
              :error="errors.fecha_nac_pac"
              disabled
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="3"
          >
            <v-text-field
              label="Lugar de nacimiento"
              v-model="paciente.lugar_nac_pac"
              :error-messages="errors.lugar_nac_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              label="Estado cívil"
              :items="[
                { text: 'Soltera/o', value: 'soltero' },
                { text: 'Casada/o', value: 'casado' },
              ]"
              v-model="paciente.edo_civil_pac"
              :error-messages="errors.edo_civil_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-select
              :items="escolaridad"
              label="Escolaridad"
              v-model="paciente.escolaridad_pac"
              :error-messages="errors.escolaridad_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
            lg="3"
          >
            <v-text-field
              label="Ocupación"
              v-model="paciente.ocupacion_pac"
              :error-messages="errors.ocupacion_pac"
              filled
            />
          </v-col>
        </v-row>
        Información de ubicación geográfica
        <v-row class="mb4">
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <AutocompleteDataEstados
              label="Estado*"
              v-model="paciente.id_edo_pac"
              :error="errors.id_edo_pac"
              @change="paciente.id_mpio_pac=null"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <AutocompleteDataMunicipios
              label="Municipio*"
              :estado="paciente.id_edo_pac"
              v-model="paciente.id_mpio_pac"
              :error="errors.id_mpio_pac"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Calle"
              v-model="paciente.calle_pac"
              :error-messages="errors.calle_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="1"
          >
            <v-text-field
              label="# int."
              v-model="paciente.num_int_pac"
              :error-messages="errors.num_int_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="1"
          >
            <v-text-field
              label="# ext."
              v-model="paciente.num_ext_pac"
              :error-messages="errors.num_ext_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Colonia"
              v-model="paciente.colonia_pac"
              :error-messages="errors.colonia_pac"
              filled
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="2"
            lg="2"
          >
            <v-text-field
              label="C.P."
              v-model="paciente.cp_pac"
              :error-messages="errors.cp_pac"
              filled
            />
          </v-col>
        </v-row>
        Información de contacto
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Celular*"
              v-model="paciente.cel_pac"
              :error-messages="errors.cel_pac"
              v-mask="'### ### ####'"
              filled
              hint="a que con el teléfono celular ingresas a este portal"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-text-field
              label="Correo electrónico*"
              v-model="paciente.email_pac"
              :error-messages="errors.email_pac"
              filled
              hint="Recuerda que con el correo electrónico ingresas a este portal"
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          to="/"
          v-text="'Cancelar'"
        />
        <v-btn
          class="primary"
          depressed
          v-text="'Actualizar información'"
          @click="_updatePaciente"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import AutocompleteDataEstados from "@/components/forms/autocompletes/AutocompleteDataEstados.vue";
import AutocompleteDataMunicipios from "@/components/forms/autocompletes/AutocompleteDataMunicipios.vue";
import DatePicker from "@/components/forms/pickers/DatePicker.vue";
export default {
  name: "PacienteForm",
  mixins: [standard, forms],
  components: {
    AutocompleteDataEstados,
    AutocompleteDataMunicipios,
    DatePicker,
  },
  data() {
    return {
      paciente: {
        nombre_pac: null,
        ape_pat_pac: null,
        ape_mat_pac: null,
        genero_pac: null,
        fecha_nac_pac: null,
        lugar_nac_pac: null,
        ocupacion_pac: null,
        escolaridad_pac: null,
        edo_civil_pac: null,
        cel_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
        calle_pac: null,
        num_int_pac: null,
        num_ext_pac: null,
        colonia_pac: null,
        cp_pac: null,
        email_pac: null,
        nombre_tutor_pac: null,
        tipo_tutor_pac: null,
      },
      errors: {
        nombre_pac: null,
        ape_pat_pac: null,
        genero_pac: null,
        fecha_nac_pac: null,
        cel_pac: null,
        email_pac: null,
        id_edo_pac: null,
        id_mpio_pac: null,
      },
      errorsMenor: {
        nombre_tutor_pac: null,
        tipo_tutor_pac: null,
      },
      escolaridad: [
        { text: "Preescolar", value: "preescolar" },
        { text: "Primaria", value: "primaria" },
        { text: "Secundaria", value: "secundaria" },
        { text: "Preparatoria", value: "preparatoria" },
        { text: "Licenciatura", value: "licenciatura" },
        { text: "Maestria", value: "maestria" },
        { text: "Posgrado", value: "posgrado" },
        { text: "Otro", value: "otro" },
        { text: "Ninguno", value: "ninguno" },
      ],
    };
  },
  methods: {
    _getPaciente() {
      this._getThings("paciente", "paciente");
    },
    _updatePaciente() {
      if (this._validateAll("paciente")) {
        const data = { paciente: this.paciente };
        this._updateAll("paciente", data, "msg", "_handlePacienteResponse");
      } else {
        this.$emit("msg", this.$lleneCampos);
      }
    },
    _handlePacienteResponse(res) {
      this.$emit("msg", res.msg);
      this.$router.push("/");
    },
  },
  created() {
    this._getPaciente();
  },
};
</script>
