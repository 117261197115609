<template>
  <div class="AutocompleteDataMunicipios">
    <v-autocomplete
      v-if="!isSelect"
      v-model="value"
      append-icon="search"
      :error-messages="error"
      item-text="municipio"
      item-value="id"
      :items="municipios"
      :label="label ? label : 'Buscar municipio'"
      filled
      @change="_searchAndSendMpio"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.municipio" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-else
      v-model="value"
      :error-messages="error"
      item-text="municipio"
      item-value="id"
      :items="municipios"
      :label="label ? label : 'Seleccionar municipio'"
      filled
      @change="_searchAndSendMpio"
    />
  </div>
</template>
<script>
import edosmpios from "@/mixins/edosmpios.js";
export default {
  name: "AutocompleteDataMunicipios",
  mixins: [edosmpios],
  props: {
    value: String,
    isSelect: Boolean,
    label: String,
    error: String,
    estado: String,
  },
  data() {
    return {};
  },
  methods: {
    _searchAndSendMpio(a) {
      this.$emit("input", a);
      let i = this.municipios.findIndex(function (b) {
        return b.id === a;
      });
      if (i >= 0) {
        this.$emit("change", this.municipios[i]);
      }
    },
    _handleChange() {
      if (this.estado) {
        // this.$emit("input", null);
        this._getMpios("?estado=" + this.estado);
      }
    },
  },
  created() {
    this._handleChange();
  },
  watch: {
    estado() {
      this._handleChange();
    },
  },
};
</script>